<template>
  <div class="has-fullheight">
    <div class="columns is-mobile is-vcentered is-variable is-1 p-2 mb-0">
      <div class="column">
        <search-input
          :placeholder="$t('inbox.threads_search_placeholder')"
          v-model="query"
          border-radius="8px"
          :button-visible="false"
        ></search-input>
      </div>
      <div class="column is-narrow">
        <router-link class="button is-primary px-1" :to="{ name: Constants.ROUTE_INBOX_CREATE_THREAD }">
          <add-icon class="add-icon-color"></add-icon>
        </router-link>
      </div>
    </div>
    <div ref="inboxContactList" class="thread-list" v-if="threads.length">
      <inbox-thread-list-item
        v-for="thread in threads"
        :thread="thread"
        :current-user-uuid="currentUserUuid"
        :key="thread.id"
        :id="`thread-${thread.id}`"
        :selected="thread.id === selectedThreadId"
      ></inbox-thread-list-item>
    </div>
    <div
      v-else-if="showPlaceholder"
      class="has-text-centered has-text-secondary is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-fullheight pb-6"
    >
      {{ $t("inbox.empty_threads_placeholder") }}
    </div>
    <div class="has-fullwidth has-fullheight is-flex is-justify-content-center is-align-items-center is-relative" v-else-if="isLoading">
      <b-loading :active="true" :is-full-page="false"></b-loading>
    </div>
  </div>
</template>

<script>
import SearchInput from "@/shared/components/SearchInput";
import InboxThreadListItem from "@/web/components/inbox/InboxThreadListItem";
import AddIcon from "@/assets/icons/add.svg";
import Constants from "@/web/constants";
import { mapActions, mapGetters } from "vuex";
import { throttle } from "@/shared/utils";

export default {
  name: "InboxContactList",

  components: { InboxThreadListItem, SearchInput, AddIcon },

  props: ["threadId"],

  data() {
    return {
      query: "",
      scrollListener: null,
    };
  },

  mounted() {
    this.loadInboxThreads().then(() => {
      if (this.threadId) {
        setTimeout(() => {
          const listElement = document.getElementById("thread-" + this.threadId);
          if (listElement) {
            var offset = listElement.getBoundingClientRect().top;
            if (offset > this.$refs.inboxContactList.offsetHeight) {
              listElement.scrollIntoView();
            }
          }
        }, 200);
      }
    });
    this.addScrollListener();
  },

  beforeDestroy() {
    this.removeScrollListener();
  },

  computed: {
    ...mapGetters("currentUser", { currentUserUuid: "uuid" }),
    ...mapGetters("inboxThreads", ["getThreads", "isLoading"]),

    Constants: () => Constants,

    scrollThreshold: () => 300,

    threads() {
      return this.getThreads(this.query, this.currentUserUuid);
    },

    selectedThreadId() {
      return Number(this.$route.params.threadId);
    },

    showPlaceholder() {
      return !this.isLoading && !this.threads.length;
    },
  },

  methods: {
    ...mapActions("inboxThreads", ["loadInboxThreads"]),

    addScrollListener() {
      if (this.$refs.inboxContactList && !this.scrollListener) {
        this.scrollListener = ev => {
          const threadList = ev.target;
          let isOnBottomOfList = threadList.scrollTop > threadList.scrollHeight - threadList.clientHeight - this.scrollThreshold;
          if (isOnBottomOfList) {
            this.onBottomOfScroll();
          }
        };
        this.$refs.inboxContactList.addEventListener("scroll", this.scrollListener);
      }
    },

    removeScrollListener() {
      if (this.scrollListener) {
        this.$refs.inboxContactList.removeEventListener("scroll", this.scrollListener);
      }
    },

    onBottomOfScroll: throttle(function (newVal) {
      this.loadInboxThreads();
    }, 1000),
  },
};
</script>

<style scoped lang="scss">
.add-icon-color {
  fill: var(--inbox-add-item-icon-color);
}

.thread-list {
  overflow-y: auto;
  max-height: calc(100% - 100px);
}
</style>
