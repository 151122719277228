<template>
  <div class="container">
    <separator class="separator is-primary"></separator>
    <div class="has-text-centered title p-1">
      {{ $t("inbox.download_mobile_app") }}
    </div>

    <a :href="appleStoreUrl" class="is-flex is-justify-content-center" target="_blank">
      <apple-store-button class="app-store-button"></apple-store-button>
    </a>
    <a :href="googlePlayUrl" class="mt-1 is-flex is-justify-content-center" target="_blank">
      <google-play-button class="google-play-button"></google-play-button>
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Separator from "@/web/components/shared/Separator";
import AppleStoreButton from "@/web/components/mobileapp/buttons/AppleStoreButton";
import GooglePlayButton from "@/web/components/mobileapp/buttons/GooglePlayButton";

export default {
  name: "InboxMobile",

  components: {
    Separator,
    AppleStoreButton,
    GooglePlayButton,
  },

  computed: {
    ...mapState(["settings", "event"]),

    appleStoreUrl() {
      const appleStoreId = this.event.apple_shop_app_id;
      return `https://apps.apple.com/us/app/ma-2-0/id${appleStoreId}`;
    },

    googlePlayUrl() {
      const googlePlayId = this.event.google_shop_app_id;
      return `https://play.google.com/store/apps/details?id=${googlePlayId}`;
    },
  },
};
</script>

<style scoped></style>
